import { getColorFromCssVariable } from "@mailbrew/uikit";

const attributeMobileStyle = `
  padding: 0;
  width: 23px !important;
  height: 23px !important;
  border-radius: 23px !important;
  zoom: 1.25;
`;

const brewExtraCss = (config, version = 2) => {
  // STYLE LATEST VERSION
  if (version === 3) {
    return `
* {
  box-sizing: border-box;
}

.main-wrapper {
  background: white;
  padding: 0;
}

.brew-actions {
  display: none;
}
.action-link {
  display: none;
}

.attribute-action:hover {
  filter: brightness(0.98);
}
.clmns .attribute-action > div {
  padding: 0px 7px !important;
}
.clmns .attribute-action span {
  display: none !important;
}

@media (max-width: 480px) {
  .attributes {
    margin-top: 4px;
    padding-bottom: 2px;
  }
  .attribute-action > div {
    ${attributeMobileStyle}
  }
  .attribute-action span {
    display: none !important;
  }
}
@media (prefers-color-scheme: dark) {
  .main-wrapper {
    background: transparent;
  }
  font[color] {
    color: hsla(170, 1%, 94%, 1);
  }
  .quote {
    background: rgba(255, 255, 255, 0.05) !important;
  }
  .attribute-action svg path {
    fill: hsla(170, 2%, 80%, 1);
    stroke: hsla(170, 2%, 80%, 1);
  }
  .c1, .title {
    color: hsla(170, 1%, 94%, 1);
  }
  .c2 {
    color: hsla(170, 1%, 94%, 1);
  }
  .c3, .prose {
    color: hsla(170, 2%, 80%, 1);
  }
  .c4 {
    color: hsla(170, 2%, 75%, 1);
  }
  .c5, .attr, a.attr {
    color: hsla(170, 3%, 55%, 1);
  }
  .tweet,
  .qt-tweet {
    border-color: hsla(170, 5%, 22%, 1);
  }
  h2.title.invert-dm {
    color: hsla(170, 1%, 94%, 1) !important;
  }
  .made-with {
    background-image: url('https://assets.mailbrew.com/newletters-templates/madeWithDark.png')
  }
}
`;
  }

  // STYLE VERSION 2
  if (version === 2) {
    return `
* {
  box-sizing: border-box;
}
width: 100%;
body, center {
  overflow-x: hidden;
  word-break: break-word;
}
code {
  white-space: pre-line;
}
p,
a,
figure {
  img {
    max-width: 100% !important;
  }
}
figure {
  max-width: 100% !important;
}
table, tbody, td, tr, div {
  min-width: 0 !important;
}
center > table:not(.template-v2) {
  background: white;
  border-radius: 6px;
}
center > table.template-v2 {
  table-layout: fixed;
}
.attributes {
  word-break: break-all;
  overflow: hidden;
  max-width: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.attribute {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  word-break: normal;
  text-overflow: ellipsis;
}
.attribute span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attribute-action:hover {
  filter: brightness(0.98);
}
.attribute.upvote, .attribute.comment {
  flex: 0 0 auto;
}

.read-online {
  display: none;
}

.source-content {
  table-layout: fixed !important;
}

.text-post {
  // display: block !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.text-post-body {
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
}

.tweet-wrapper>td {
  display: block;
  width: 100%;
}

${/* Hide source action button */ ""}
.title-link + a {
  display: none !important;
}

${/* Fixes for brews before 2020/12/15 edit */ ""}
td[style*="width: 54px"] {
  flex: 0 0 54px;
}
td[style*="width: 88px"] {
  flex: 0 0 88px;
}
td[style*="width: 54px"] {
  flex: 0 0 54px;
}
td[style*="width: 80px"] {
  flex: 0 0 80px;
}

${/* Fixes for brews before 2020/12/20 columns fixes */ ""}
@media (min-width: 671px) {
  table[style*="328px"] {
    max-width: 326px !important;
  }
  table[style*="218px"] {
    max-width: 217px !important;
  }
}

.columns-2 .attribute-action > div, .columns-3 .attribute-action > div {
  padding: 0px 7px !important;
}
.columns-2 .attribute-action span, .columns-3 .attribute-action span {
  display: none !important;
}

@media (max-width: 710px) {
  center > .template-v2 > tbody > tr:not(:last-of-type) > td {
    padding: 0 !important;
  }
}
@media (max-width: 480px) {
  .attributes {
    margin-top: 4px;
    padding-bottom: 2px;
  }
  .attribute-action > div {
    ${attributeMobileStyle}
  }
  .attribute-action span {
    display: none !important;
  }
}
@media (max-width: 342px) {
  center {
    zoom: 0.95;
  }
  img[src*="icons/link.png"] {
    display: none !important;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background: transparent !important;
  }
  .attribute-action svg path {
    fill: ${getColorFromCssVariable(config.colors.c2)};
    stroke: ${getColorFromCssVariable(config.colors.c2)};
  }
  .template-v2 {
    background: transparent !important;
  }
  font[color] {
    color: ${getColorFromCssVariable(config.colors.c1)} !important;
  }
  .template-v2 .title,
  .template-v2 td a p,
  .template-v2 a > h1,
  .template-v2 .text_post .description p,
  .template-v2 h4 a,
  #header div>span {
    color: ${getColorFromCssVariable(config.colors.c1)} !important;
  }
  .template-v2 .date {
    color: ${getColorFromCssVariable(config.colors.c1)} !important;
  }
  .template-v2 .date-2 {
    color: ${getColorFromCssVariable(config.colors.c2)} !important;
  }
  .template-v2 .quote td,
  .template-v2 .with-bg {
    color: ${getColorFromCssVariable(config.colors.c2)} !important;
    background: rgba(255, 255, 255, 0.05) !important;
  }
  .template-v2 .separator {
    border-top-color: ${getColorFromCssVariable(config.colors.c5)} !important;
  }
  .template-v2 .description,
  .template-v2 .description p {
    color: ${getColorFromCssVariable(config.colors.c3)} !important;
  }
  .template-v2 .metric-post h4 {
    color: ${getColorFromCssVariable(config.colors.c2)} !important;
  }
  .template-v2 .metric-post h4 .label {
    color: ${getColorFromCssVariable(config.colors.c3)} !important;
  }
  .template-v2 .footer-start {
    background: transparent !important;
  }
  .template-v2 .tweet,
  .template-v2 .quoted-tweet {
    border-color: ${getColorFromCssVariable(config.colors.c5)} !important;
  }
  .template-v2 .tweet .name,
  .template-v2 .tweet .tweet-body {
    color: ${getColorFromCssVariable(config.colors.c1)} !important;
  }
  .template-v2 .tweet .handle,
  .template-v2 .tweet .retweeted {
    color: ${getColorFromCssVariable(config.colors.c3)} !important;
  }
}`;
  }

  return ``;
};

export default brewExtraCss;
