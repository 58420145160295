/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Box, useConfig } from "@mailbrew/uikit";
import brewExtraCss from "./brewExtraCss";

const BrewExtraCssWrapper = ({ children, baseCss, customCss, templateVersion = 1, ...rest }) => {
  const config = useConfig();
  return (
    <Box
      css={css`
        ${baseCss}
        ${brewExtraCss(config, templateVersion)}
        ${customCss}
      `}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default BrewExtraCssWrapper;
