import { getColorFromCssVariable, notif, NotifWithButton, Section, useConfig } from "@mailbrew/uikit";
import api, { axiosSwrFetcher } from "dependencies/api";
import { useRouter } from "next/router";
import { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import useSWR from "swr";
import urls from "urls";
import domainFromURL from "utils/domainFromURL";
import BrewIssueViewer from "./BrewIssueViewer";
import InboxMessageModal from "./InboxMessageModal";
import KeepReadingButton from "./KeepReadingButton";
import { ReaderViewModal } from "./ReaderViewModal";

const OwnerBrewIssueViewer = ({ issue, noPadding }) => {
  const config = useConfig();
  const iFrameRef = useRef(null);
  const user = useSelector(authUserSelector);
  const router = useRouter();

  /* ------------------------ Fetch issue html content ------------------------ */

  const { data: messageHtml, error: fetchingError } = useSWR(issue?.content_url, axiosSwrFetcher);

  /* ---------------------------- Handle Read Later --------------------------- */

  const handleReadLater = async (url) => {
    notif.promise(api.post("/add_read_later_item_via_url/", { url }), {
      loading: "Saving...",
      success: (
        <NotifWithButton
          msg="Saved"
          action="View"
          variant="subtle"
          onClick={() => {
            notif.dismiss();
            router.push(urls.saved());
          }}
        />
      ),
      error: (err) => {
        const message = err?.response?.data?.detail;
        return message ?? "Oops, there was an error fetching your link.";
      },
    });
  };

  /* -------------------------------- handlers -------------------------------- */

  const handleEditHTMLElementBeforeInjection = (htmlElement) => {
    if (!htmlElement) return;

    /* ------------------------------ In-App Links ------------------------------ */

    const allLinks = htmlElement.querySelectorAll("a");
    allLinks.forEach((link) => {
      const url = link?.href;

      if (url && url.includes("https://app.mailbrew.com")) {
        link.onclick = (e) => {
          e.preventDefault();
          router.push(url.replace("https://app.mailbrew.com", ""));
        };
      } else {
        link.onclick = (e) => {
          user && api.post(`/issues/${issue.id}/open_link/`, { url });
        };
      }
    });

    /* ------------------------------ Edit sources ------------------------------ */

    const sourceTitles = htmlElement.querySelectorAll(".src-title");
    sourceTitles.forEach((title) => {
      const { sourceId } = title?.dataset || {};

      if (sourceId && !title.querySelector(".edit-src-btn")) {
        const editSourceButton = htmlElement.ownerDocument.createElement("a");
        editSourceButton.href = window.location.origin + `/edit/${issue.newsletter.id}/${sourceId}`;
        editSourceButton.target = "_blank";
        editSourceButton.className = "edit-src-btn c5";
        editSourceButton.innerHTML = "...";
        editSourceButton.title = "Edit this source";

        title.appendChild(editSourceButton);
      }
    });

    /* ----------------------------- Handle Actions ----------------------------- */

    // Find Tweets

    const tweets = htmlElement.querySelectorAll(".tweet");
    tweets.forEach((tweet) => {
      const url = tweet.querySelector(".tw-usr")?.href;
      if (tweetSaveButtonEnabled("save", tweet) && url) {
        styleDomNode(tweet, {
          position: "relative",
        });
        createAttribute(htmlElement, {
          config: config,
          appendTo: tweet,
          svg: readLaterSvg,
          label: "SAVE",
          className: "save",
          onClick: () => {
            handleReadLater(url);
          },
          wrapperStyle: {
            paddingRight: 0,
            position: "absolute",
            top: "12px",
            right: "12px",
          },
        });
      }
    });

    // Find Post Titles

    const nonInboxPosts = Array.from(htmlElement.querySelectorAll("h4.post-title")).filter(
      (t) => !isInboxMessage(t.querySelector("a")?.href)
    );

    const inboxPosts = Array.from(htmlElement.querySelectorAll("h4.post-title")).filter((t) =>
      isInboxMessage(t.querySelector("a")?.href)
    );
    const foundNewsletters = inboxPosts?.map((post) => getNewsletterId(post.querySelector("a")?.href));
    setNewslettersIds(foundNewsletters);

    nonInboxPosts.forEach((postTitle) => {
      const link = postTitle.querySelector("a");
      const url = link?.href;

      const sourceType = getSourceTypeFromElement(postTitle);

      // Read Later

      if (saveEnabled(postTitle)) {
        createAttribute(htmlElement, {
          config: config,
          prependTo: postTitle.parentNode.querySelector(".attributes"),
          svg: readLaterSvg,
          label: "SAVE",
          className: "save",
          onClick: () => {
            handleReadLater(url);
          },
        });
      }

      // Reader Mode

      if (readerModeEnabled(postTitle)) {
        createAttribute(htmlElement, {
          config: config,
          prependTo: postTitle.parentNode.querySelector(".attributes"),
          svg: readerModeSvg,
          label: "READ",
          className: "reader",
          onClick: () => {
            setReaderModeUrl(url);
            if (sourceType === "read_later") {
              setReaderModeHideReadLater(true);
            } else {
              setReaderModeHideReadLater(false);
            }
            setReaderModeShown(true);
          },
        });
      }
    });

    inboxPosts.forEach((inboxPostTitle, index) => {
      const linkElement = inboxPostTitle.querySelector("a");
      const inboxUrl = linkElement?.href;

      // Read Later

      if (saveEnabled(inboxPostTitle)) {
        createAttribute(htmlElement, {
          config: config,
          prependTo: inboxPostTitle.parentNode.querySelector(".attributes"),
          svg: readLaterSvg,
          label: "SAVE",
          className: "save",
          onClick: () => {
            handleReadLater(inboxUrl);
          },
        });
      }

      // Inbox

      if (inboxReadModeEnabled(inboxPostTitle, inboxUrl)) {
        function handleClick() {
          setCurrentNewsletterIndex(index);
          setNewsletterModalShown(true);
        }
        createAttribute(htmlElement, {
          config: config,
          prependTo: inboxPostTitle.parentNode.querySelector(".attributes"),
          svg: readerModeSvg,
          label: "READ",
          className: "reader",
          onClick: () => {
            handleClick();
          },
        });

        if (getNewsletterId(inboxUrl)) {
          linkElement.onclick = (e) => {
            if (e.metaKey) {
            } else {
              e.preventDefault();
              handleClick();
            }
          };
        }
      }
    });
  };

  /* ------------------------------- Reader Mode Modal ------------------------------ */

  const [readerModeUrl, setReaderModeUrl] = useState(null);
  const [readerModeShown, setReaderModeShown] = useState(false);
  const [readerModeHideReadLater, setReaderModeHideReadLater] = useState(false);

  /* ---------------------------- Newsletter Modal ---------------------------- */

  const [newslettersIds, setNewslettersIds] = useState(null);
  const [currentNewsletterIndex, setCurrentNewsletterIndex] = useState(null);

  const prevNewsletterId = newslettersIds?.[currentNewsletterIndex - 1];
  const nextNewsletterId = newslettersIds?.[currentNewsletterIndex + 1];

  const [newsletterModalShown, setNewsletterModalShown] = useState(false);

  return (
    <Fragment>
      <InboxMessageModal
        key="newsletters-modal"
        id={newslettersIds?.[currentNewsletterIndex]}
        prevId={prevNewsletterId}
        nextId={nextNewsletterId}
        onPrevClick={() => setCurrentNewsletterIndex(currentNewsletterIndex - 1)}
        onNextClick={() => setCurrentNewsletterIndex(currentNewsletterIndex + 1)}
        show={newsletterModalShown}
        setShow={setNewsletterModalShown}
        src="webapp-brew"
      />
      <ReaderViewModal
        show={readerModeShown}
        setShow={setReaderModeShown}
        url={readerModeUrl}
        hideReadLater={readerModeHideReadLater}
        source="Brews"
      />
      <Section noPadding noLateralPadding style={{ flex: "999 1 auto" }} background={config.colors.bg0}>
        <BrewIssueViewer
          key={issue?.id}
          ref={iFrameRef}
          html={messageHtml}
          showCard={false}
          hideHeaderAndFooter
          editHTMLElementBeforeInjection={handleEditHTMLElementBeforeInjection}
          error={fetchingError || (issue && !issue.content_url)}
          noPadding={noPadding}
        />
        <KeepReadingButton
          kind="brew"
          id={issue.id}
          initialReadingProgress={issue.reading_progress}
          objRef={iFrameRef}
          fixedPosition
          delay={1000}
        />
      </Section>
    </Fragment>
  );
};

const svgColor = "hsl(206, 12%, 55%)";

const readerModeSvg = `<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 2.5H8.5" stroke="${svgColor}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.5 5.5H8.5" stroke="${svgColor}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.5 8.5H5.5" stroke="${svgColor}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const readLaterSvg = `<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 2.5C1 1.94772 1.44772 1.5 2 1.5H8C8.55228 1.5 9 1.94772 9 2.5V9.39812C9 9.8277 8.49404 10.0573 8.17075 9.7744L5.6585 7.57619C5.28148 7.24629 4.71852 7.24629 4.3415 7.57619L1.82925 9.7744C1.50596 10.0573 1 9.82769 1 9.39812V2.5Z" fill="${svgColor}"/>
</svg>
`;

function createAttribute(htmlElement, props) {
  const { config, prependTo, className, appendTo, svg, label, onClick, style, wrapperStyle } = props;
  const document = htmlElement.ownerDocument;

  // Create wrapper element
  const wrapperDiv = document.createElement("div");
  wrapperDiv.classList.add("attribute-action");
  wrapperDiv.classList.add(className);

  // Create button
  const buttonDiv = document.createElement("div");
  wrapperDiv.appendChild(buttonDiv);

  // Add svg
  buttonDiv.innerHTML = svg;

  // Add label
  const labelSpan = document.createElement("span");
  labelSpan.innerHTML = label;
  buttonDiv.appendChild(labelSpan);

  styleDomNode(wrapperDiv, {
    paddingRight: "7px",
    flex: "0 0 auto",
    ...wrapperStyle,
  });
  styleDomNode(buttonDiv, {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "21px",
    fontSize: "12px",
    fontWeight: "600",
    color: "hsl(209, 10%, 65%)",
    background: getColorFromCssVariable(config.colors.buttonWhite),
    boxShadow: `0 2px 2px ${getColorFromCssVariable(config.colors.shadowColor)}`,
    border: `1px solid ${getColorFromCssVariable(config.colors.uiBorderColor)}`,
    borderRadius: "21px",
    padding: "0px 8px 0px 7px",
    ...style,
  });
  styleDomNode(buttonDiv.querySelector("svg"), {
    flex: "1 0 10px",
    width: "10px",
    height: "11px",
    position: "relative",
  });
  styleDomNode(labelSpan, {
    marginLeft: "3px",
  });
  wrapperDiv.onclick = (e) => {
    // Set found URL and open Reader Mode modal
    e.preventDefault();
    onClick && onClick();
  };
  if (prependTo) {
    prependTo.insertBefore(wrapperDiv, prependTo.firstChild);
  }
  if (appendTo) {
    appendTo.appendChild(wrapperDiv);
  }
}

function styleDomNode(node, style) {
  Object.keys(style).map((key) => (node.style[key] = style[key]));
}

function readerModeEnabled(postTitle) {
  return actionButtonEnabled("reader", postTitle, {
    excludeDomains: READER_MODE_EXCLUDED_DOMAINS,
    excludeSourceTypes: READER_MODE_EXCLUDED_SOURCE_TYPES,
  });
}

function inboxReadModeEnabled(postTitle, url) {
  return (
    url &&
    url.includes("mailbrew.com/inbox") &&
    actionButtonEnabled("reader", postTitle, {
      excludeDomains: [],
      excludeSourceTypes: [],
    })
  );
}

function isInboxMessage(url) {
  return url && url.includes("mailbrew.com/inbox");
}

function saveEnabled(postTitle) {
  return actionButtonEnabled("save", postTitle, {
    excludeDomains: [],
    excludeSourceTypes: SAVE_EXCLUDED_SOURCE_TYPES,
  });
}

function getSourceTypeFromElement(el) {
  let closestParent = el.closest(".src") || el.closest(".source-content");
  let sourceContainerClassList = closestParent?.classList;
  if (!sourceContainerClassList) return null;
  const classes = Array.from(sourceContainerClassList);
  const newSourceClass = classes.find((c) => c.includes("src_"));
  if (newSourceClass) {
    return newSourceClass.split("src_")[1];
  }
  const oldSourceClass = classes.find((c) => c.includes("source_"));
  if (oldSourceClass) {
    return oldSourceClass.split("source_")[1];
  }
  return null;
}

function actionButtonEnabled(actionButtonClassName, postTitleElement, { excludeDomains, excludeSourceTypes }) {
  const sourceType = getSourceTypeFromElement(postTitleElement);

  if (!sourceType) return false;

  const url = postTitleElement.querySelector("a")?.href;
  const domain = domainFromURL(url);
  return (
    !postTitleElement.parentNode.querySelector("." + actionButtonClassName) &&
    url &&
    !excludeDomains.includes(domain) &&
    !excludeSourceTypes.includes(sourceType) &&
    !url.match(/\.(jpeg|jpg|gif|png)$/)
  );
}

function tweetSaveButtonEnabled(actionButtonClassName, rootElement) {
  return !rootElement.querySelector("." + actionButtonClassName);
}

function getNewsletterId(inboxUrl) {
  return inboxUrl && inboxUrl.split("/")[4];
}

const READER_MODE_EXCLUDED_DOMAINS = [
  "app.mailbrew.com",
  "twitter.com",
  "facebook.com",
  "fb.me",
  "youtube.com",
  "youtu.be",
  "reddit.com",
];
const READER_MODE_EXCLUDED_SOURCE_TYPES = ["product_hunt", "inbox"];
const SAVE_EXCLUDED_SOURCE_TYPES = ["read_later"];

export default OwnerBrewIssueViewer;
