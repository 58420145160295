import { DropDownMenu, notif, useConfig } from "@mailbrew/uikit";
import api from "dependencies/api";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mutate } from "swr";
import apiErrMsg from "utils/apiErrMsg";
import { keyNewsletterById } from "utils/swrKeys";
import useCloneNewsletter from "../hooks/useCloneNewsletter";
import { authUserSelector } from "../reducers/authReducer";
import { duplicateNewsletter } from "../reducers/newslettersReducer";
import urls from "../urls";
import { BrewPublishModal } from "./PublishBrewButton";

const BrewManageOptionsDropdown = ({ newsletter, author, forceIsSelf, handleGenerateNewIssue }) => {
  const dispatch = useDispatch();
  const config = useConfig();
  const user = useSelector(authUserSelector);
  const isSelf = author?.id === user?.id || forceIsSelf;
  const router = useRouter();

  const [publishModalShown, setPublishModalShown] = useState(false);

  const { public: newsletterPublic } = newsletter;
  const [isNewsletterPublic, setIsNewsletterPublic] = useState(newsletterPublic);

  const { cloneNewsletter } = useCloneNewsletter();

  const handleCloneClick = async () => {
    const confirmed = window.confirm(`This will create your own personal copy of "${newsletter.title}". Continue?`);

    if (confirmed) {
      cloneNewsletter(newsletter.id);
    }
  };

  const handleDeleteClick = async (n) => {
    const confirmed = window.confirm(`Delete "${newsletter.title}"? This cannot be undone.`);

    if (confirmed) {
      try {
        await api.delete(keyNewsletterById(newsletter.id));
        mutate("/newsletters/", (brews) => brews.filter((brew) => brew.id !== newsletter.id), false);
      } catch (err) {
        notif.error(apiErrMsg(err, "Error deleting your brew"));
      }
    }
  };

  const handleClickDuplicate = async () => {
    await dispatch(duplicateNewsletter(newsletter.id));
    mutate("/newsletters/");
  };

  const handleClickManageSubscribers = () => {
    router.push(urls.manageSubscribers(newsletter.id));
  };

  const handleClickEmbed = () => {
    router.push(urls.editNewsletter(newsletter.id));
  };

  const handleSelectOption = (option) => {
    if (isSelf) {
      selfOptions.find((o) => o.value === option).handler();
    } else {
      publicOptions.find((o) => o.value === option).handler();
    }
  };

  const selfOptions = [
    handleGenerateNewIssue && {
      value: "generate",
      name: "Generate new issue",
      icon: "refresh",
      handler: handleGenerateNewIssue,
    },
    {
      value: "delete",
      name: "Delete brew",
      icon: "trashBold",
      handler: handleDeleteClick,
    },
    {
      value: "duplicate",
      name: "Duplicate brew",
      icon: "copy",
      handler: handleClickDuplicate,
    },
    {
      value: "publish",
      name:
        (newsletter.public ? "Public" : "Private") +
        (newsletter.subscribers_count ? ` (${newsletter.subscribers_count} subs)` : ""),
      icon: newsletter.public ? "worldAltBold" : "lock",
      handler: () => setPublishModalShown(true),
    },
    {
      value: "subscribers",
      name: "Manage subscribers",
      icon: "people",
      handler: handleClickManageSubscribers,
    },
  ].filter((o) => !!o);

  if (newsletter.public) {
    selfOptions.push({
      value: "embed",
      name: "Embed on your site",
      icon: "code",
      handler: handleClickEmbed,
    });
  }

  const publicOptions = [
    {
      value: "clone",
      name: "Clone and Customize",
      icon: "copy",
      handler: handleCloneClick,
    },
  ];

  return (
    <Fragment>
      <DropDownMenu
        icon="dots"
        iconColor={config.colors.c4}
        options={isSelf ? selfOptions.map((o) => o.value) : publicOptions.map((o) => o.value)}
        optionsNames={isSelf ? selfOptions.map((o) => o.name) : publicOptions.map((o) => o.name)}
        optionsIcons={isSelf ? selfOptions.map((o) => o.icon) : publicOptions.map((o) => o.icon)}
        onSelect={handleSelectOption}
      />
      <BrewPublishModal
        isNewsletterPublic={isNewsletterPublic}
        setIsNewsletterPublic={setIsNewsletterPublic}
        newsletter={newsletter}
        show={publishModalShown}
        setShow={setPublishModalShown}
      />
    </Fragment>
  );
};

export default BrewManageOptionsDropdown;
