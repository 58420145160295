import { Grid } from "@mailbrew/uikit";
import { motion } from "framer-motion";

const BrewsGrid = (props) => {
  const { children } = props;

  return (
    <Grid as={motion.div} gap="1.5em" style={{ marginBottom: "2.5em" }} breakAt={800}>
      {children}
    </Grid>
  );
};

export default BrewsGrid;
